.keks {
  min-width: fit-content;
  width: 100%;
}

.aa div {
  margin-top: 1px;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.keks .aa {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 1560px;
}

td:last-child {
  min-width: fit-content;
}

td:first-child {
  min-width: 100px;
}

td {
  min-width: 80px;
  border-bottom: 2px solid #e7e7e7;
}

h1 {
  color: #e20074;
  margin-left: 15px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 12px;
  text-align: left;
}

th:nth-child(1),
td:nth-child(1) {
  width: 80px;
  padding: 12px;
  text-align: left;
}

th {
  background-color: #e20074;
  color: #fff;
  font-weight: bold;
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.1);
  font-size: 1.2em;
  border-right: 2px solid white;
  min-width: fit-content;
}

td {
  border-right: 2px solid #e7e7e7;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

div.error {
  color: red;
  text-align: center;
  margin-top: 20px;
}

div.loading {
  text-align: center;
  font-size: 18px;
  color: #555;
}

#kzx {
  display: block;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.topKeks th:nth-child(1) {
  border-right: 2px solid #fff;
}

.topKeks th:nth-child(4) {
  border-right: 2px solid #fff;
}

.topKeks th:nth-child(7) {
  border-right: 2px solid #fff;
}

.topKeks td:nth-child(1) {
  border-right: 2px solid #e7e7e7;
}

.topKeks td:nth-child(4) {
  border-right: 2px solid #e7e7e7;
}

.topKeks td:nth-child(7) {
  border-right: 2px solid #e7e7e7;
}

#eewew th {
  border: none;
  border-right: 2px dashed #fff;
}

#eewew th:last-child{
  border-right: 2px solid #fff;
}

#eewew .topKeks th:nth-child(1) {
  border-right: 2px solid #fff;
}

#eewew .topKeks th:nth-child(4) {
  border-right: 2px solid #fff;
}

#eewew .topKeks th:nth-child(7) {
  border-right: 2px solid #fff;
}

.date-picker {
  width: fit-content;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M18 14.88 8.16 3.15c-.26-.31-.76-.12-.76.28v15.31c0 .36.42.56.7.33l3.1-2.6 1.55 4.25c.08.22.33.34.55.26l1.61-.59a.43.43 0 0 0 .26-.55l-1.55-4.25h4.05c.36 0 .56-.42.33-.7Z"></path></svg>')
      6 0,
    auto;
  color: #e20074;
  font-size: 1.1em;
  font-weight: 600;
  margin-left: 100px;
}

.date-picker input {
  margin: 0 10px 0 2px;
  padding: 2px;
  border: 2px solid #e20074;
  border-radius: 5px;
  color: #e20074;
  font-size: 1em;
}

.date-picker input[type="date"]::-webkit-calendar-picker-indicator {
  color: red;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23e20074" fill-opacity=".54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
  opacity: 1;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>')
      7 0,
    auto;
  display: block;
  width: 20px;
  height: 20px;
  border-width: thin;
}

.group {
  margin-left: 100px;
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  min-width: 150px;
}

.icon {
  position: absolute;
  left: 1rem;
  fill: #e20074;
  width: 1rem;
  height: 1rem;
}

.kekw {
  width: 100%;
  height: 40px;
  line-height: 28px;
  padding: 0 1rem;
  padding-left: 2.5rem;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  background-color: #f3f3f4;
  color: #0d0c22;
  transition: 0.3s ease;
  color: #e20074;
}

.kekw::placeholder {
  color: #9e9ea7;
}

.kekw:focus,
.kekw:hover {
  outline: none;
  border-color: rgba(234, 76, 137, 0.4);
  background-color: #fff;
  box-shadow: 0 0 0 4px rgb(234 76 137 / 10%);
}

.icon {
  position: absolute;
  left: 1rem;
  fill: #e20074;
  width: 1rem;
  height: 1rem;
}

.kekw[type="search"]::-webkit-search-clear-button {
  background-color: #e20074;
  color: red;
}

.kekw[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  background-color: #e20074;
  -webkit-mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  background-size: 15px 15px;
  height: 15px;
  width: 15px;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFFFFF" stroke="%23E20074" stroke-width="1" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>')
      7 0,
    auto;
}

td:nth-child(2) {
  width: fit-content;
  white-space: nowrap;
}

.kekzzzz {
  margin-left: -320px;
}

#eewew {
  min-width: 1900px;
}

#ewsxxc th,
#ewsxxcewqes th,
#ekqwijsc th {
  width: fit-content;
  white-space: nowrap;
}

input[type="date"]::-webkit-datetime-edit,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  color: #fff;
  position: relative;
}

input[type="date"]::-webkit-datetime-edit-year-field {
  position: absolute !important;
  border-left: 1px solid #8c8c8c;
  left: 56px;
  padding-left: 7px;
  margin-left: 3px;
  color: #e20074;
}

input[type="date"]::-webkit-datetime-edit-month-field {
  position: absolute !important;
  border-left: 1px solid #8c8c8c;
  padding-left: 7px;
  left: 26px;
  color: #e20074;
  margin-left: 2px;
}

input[type="date"]::-webkit-datetime-edit-day-field {
  position: absolute !important;
  left: 4px;
  color: #e20074;
}